import React from 'react'
import RangeSlider from 'react-range-slider-input'
import PIX from 'react-qrcode-pix'
import PropTypes from 'prop-types'

import 'react-range-slider-input/dist/style.css'
import '../css/App.css'

function renderGift(value) {
  if (value <= 10) {
    return ['Lagartinha', '10gusanito.png']
  }
  if (value <= 20) {
    return ['Brinquedo de Praia', '20praia.png']
  }
  if (value <= 30) {
    return ['Adesivos', '30Adesivos.png']
  }
  if (value <= 40) {
    return ['Livros de Pintar', '40Livropintar.png']
  }
  if (value <= 50) {
    return ['Animais da Fazenda', '50Animais.png']
  }
  if (value <= 60) {
    return ['Livros', '60Livro.png']
  }
  if (value <= 70) {
    return ['Carrinho de Compras', '70Carrinho.png']
  }
  if (value <= 80) {
    return ['Areia Cinetica', '80areiacinetica.png']
  }
  if (value <= 90) {
    return ['Boneca', '90boneca.png']
  }
  if (value <= 100) {
    return ['Quebra Cabeças', '100QuebraCabeca.png']
  }
  if (value <= 110) {
    return ['Blocos de Montar', '110Lego.png']
  }
  if (value <= 120) {
    return ['Piscina de bolinhas', '120PiscinaBolinha.png']
  }
  if (value <= 130) {
    return ['Cozinha', '130cozinha.png']
  }
  if (value <= 140) {
    return ['Torre de Observação', '140torreobservacao.png']
  }
  if (value <= 150) {
    return ['Cavalete Pintura', '150Cavalete.png']
  }
  if (value <= 160) {
    return ['Eletrodomesticos', '160eletrodomesticos.png']
  }
  if (value <= 170) {
    return ['Barraca', '170barraca.png']
  }
  if (value <= 180) {
    return ['Estante de Livros', '180estantelivro.png']
  }
  if (value <= 190) {
    return ['Bicicleta', '190bicicleta.png']
  }
  if (value <= 200) {
    return ['Cesta de Basquete', '200cestabasquete.png']
  }
  return ['Carrinho', '200+carrinho.png']
}

function Gift(props) {
  const { width } = props
  const { height } = props
  const giftWidth = (width * 22) / 30
  const [price, setPrice] = React.useState(40)
  const parsedPrice = price < 201 ? `Preço: R$ ${price}` : 'Outros valores'
  const [gift, gitfPng] = renderGift(price)
  const now = new Date().getTime().toString()
  const pstyle = {
    fontWeight: '400',
    fontSize: `${height / 61}px`,
    lineHeight: `${height / 37}px`,
    color: '#333333',
    width: `${giftWidth}px`,
  }
  return (
    <div
      style={{
        paddingTop: `${height / 5}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: `${height / 135}px`,
      }}
    >
      <p style={{ ...pstyle, textAlign: 'left' }}>Presente: {gift}</p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p
          style={{ ...pstyle, textAlign: 'left', width: `${giftWidth / 4}px` }}
        >
          {parsedPrice}
        </p>
        <p
          style={{
            ...pstyle,
            textAlign: 'right',
            width: `${(giftWidth * 3) / 4}px`,
          }}
        >
          Arraste a barra para mudar o presente
        </p>
      </div>
      <div
        style={{
          alignItems: 'center',
          width: `${giftWidth}px`,
          height: `${height / 29}px`,
          margin: `${height / 135}px`,
        }}
      >
        <RangeSlider
          defaultValue={[0, 40]}
          thumbsDisabled={[true, false]}
          min={10}
          max={202}
          step={2}
          value={[0, price]}
          onInput={(event) => {
            setPrice(event[1])
          }}
          id="range-slider"
          style={{
            height: `${height / 69}px`,
          }}
        />
      </div>
      <p style={{ ...pstyle, textAlign: 'center' }}>
        Leia o QR Code abaixo ou utilize a chave pix: (31) 99804-4161
      </p>
      <p style={{ ...pstyle, textAlign: 'center' }}>
        Favor conferir o nome do destinatario. Marcelo B Junqueira
      </p>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: `${height / 37}px`,
        }}
      >
        <div style={{ width: `${giftWidth / 2}px`, textAlign: 'right' }}>
          <PIX
            pixkey="+5531998044161"
            merchant="Marcelo Bartolomeu Junqueira"
            city="Congonhas"
            cep="25.850-000"
            amount={price < 200 ? price : null}
            code={gift.substr(0, 10) + now}
            resize={`${giftWidth / 3}px`}
          />
        </div>
        <div style={{ width: `${giftWidth / 2}px`, textAlign: 'left' }}>
          <img
            src={`images/gifts/${gitfPng}`}
            alt="distributoor"
            style={{
              width: `${giftWidth / 3}px`,
              height: `${giftWidth / 3}px`,
            }}
          />
        </div>
      </div>
    </div>
  )
}

Gift.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default Gift
