import React from 'react'
import PropTypes from 'prop-types'
import Gift from './gift'

import '../css/App.css'

function Home(props) {
  const screenWidth = window.innerWidth
  const width = screenWidth < 763 ? screenWidth : 763
  const height = width * 1.5
  const handleClick = () => {
    props.setIsPrice(true)
  }
  return (
    <div
      style={{
        backgroundImage: `url(images/background.png)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: `${width}px ${height}px`,
        margin: 'auto',
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      <Gift width={width} height={height} />
      <button
        onClick={handleClick}
        type="button"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 'auto',
          width: `${width / 7}px`,
          height: `${width / 15}px`,
          marginTop: `${width / 10}px`,
          background: 'none',
          border: '1px solid #DF552A',
          borderRadius: `${width / 10}px`,
          cursor: 'pointer',
        }}
      >
        <p
          style={{
            fontWeight: '700',
            fontSize: '100%',
            lineHeight: '100%',
            textAlign: 'center',
            color: '#DF552A',
          }}
        >
          Fotos
        </p>
      </button>
    </div>
  )
}

Home.propTypes = {
  setIsPrice: PropTypes.func.isRequired,
}

export default Home
