import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { saveAs } from 'file-saver'
import '../css/App.css'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

function Fotos() {
  const fotosList = [
    '_MG_2999.jpg',
    '_MG_3002.jpg',
    '_MG_3007.jpg',
    '_MG_3009.jpg',
    '_MG_3010.jpg',
    '_MG_3011.jpg',
    '_MG_3012.jpg',
    '_MG_3013.jpg',
    '_MG_3017.jpg',
    '_MG_3018.jpg',
    '_MG_3024.jpg',
    '_MG_3025.jpg',
    '_MG_3026.jpg',
    '_MG_3031.jpg',
    '_MG_3033.jpg',
    '_MG_3039.jpg',
    '_MG_3041.jpg',
    '_MG_3045.jpg',
    '_MG_3050.jpg',
    '_MG_3054.jpg',
    '_MG_3059.jpg',
    '_MG_3062.jpg',
    '_MG_3065.jpg',
    '_MG_3067.jpg',
    '_MG_3069.jpg',
    '_MG_3071.jpg',
    '_MG_3073.jpg',
    '_MG_3079.jpg',
    '_MG_3080.jpg',
    '_MG_3084.jpg',
    '_MG_3086.jpg',
    '_MG_3087.jpg',
    '_MG_3088.jpg',
    '_MG_3094.jpg',
    '_MG_3096.jpg',
    '_MG_3102.jpg',
    '_MG_3105.jpg',
    '_MG_3107.jpg',
    '_MG_3112.jpg',
    '_MG_3114.jpg',
    '_MG_3117.jpg',
    '_MG_3119.jpg',
    '_MG_3127.jpg',
    '_MG_3129.jpg',
    '_MG_3131.jpg',
    '_MG_3132.jpg',
    '_MG_3133.jpg',
    '_MG_3137.jpg',
    '_MG_3144.jpg',
    '_MG_3153.jpg',
    '_MG_3156.jpg',
    '_MG_3159.jpg',
    '_MG_3163.jpg',
    '_MG_3165.jpg',
    '_MG_3169.jpg',
    '_MG_3172.jpg',
    '_MG_3175.jpg',
    '_MG_3176.jpg',
    '_MG_3178.jpg',
    '_MG_3181.jpg',
    '_MG_3182.jpg',
    '_MG_3188.jpg',
    '_MG_3190.jpg',
    '_MG_3194.jpg',
    '_MG_3195.jpg',
    '_MG_3199.jpg',
    '_MG_3203.jpg',
    '_MG_3211.jpg',
    '_MG_3212.jpg',
    '_MG_3215.jpg',
    '_MG_3217.jpg',
    '_MG_3218.jpg',
    '_MG_3222.jpg',
    '_MG_3223.jpg',
    '_MG_3231.jpg',
    '_MG_3234.jpg',
    '_MG_3240.jpg',
    '_MG_3244.jpg',
    '_MG_3249.jpg',
    '_MG_3252.jpg',
    '_MG_3254.jpg',
    '_MG_3255.jpg',
    '_MG_3258.jpg',
    '_MG_3270.jpg',
    '_MG_3271.jpg',
    '_MG_3274.jpg',
    '_MG_3278.jpg',
    '_MG_3280.jpg',
    '_MG_3282.jpg',
    '_MG_3284.jpg',
    '_MG_3290.jpg',
    '_MG_3292.jpg',
    '_MG_3294.jpg',
    '_MG_3299.jpg',
    '_MG_3302.jpg',
    '_MG_3305.jpg',
    '_MG_3306.jpg',
    '_MG_3311.jpg',
    '_MG_3312.jpg',
    '_MG_3315.jpg',
    '_MG_3318.jpg',
    '_MG_3321.jpg',
    '_MG_3324.jpg',
    '_MG_3326.jpg',
    '_MG_3328.jpg',
    '_MG_3330.jpg',
    '_MG_3332.jpg',
    '_MG_3333.jpg',
    '_MG_3337.jpg',
    '_MG_3338.jpg',
    '_MG_3345.jpg',
    '_MG_3349.jpg',
    '_MG_3350.jpg',
    '_MG_3360.jpg',
    '_MG_3361.jpg',
    '_MG_3366.jpg',
    '_MG_3373.jpg',
    '_MG_3374.jpg',
    '_MG_3376.jpg',
    '_MG_3378.jpg',
    '_MG_3383.jpg',
    '_MG_3385.jpg',
    '_MG_3388.jpg',
    '_MG_3389.jpg',
    '_MG_3394.jpg',
    '_MG_3397.jpg',
    '_MG_3404.jpg',
    '_MG_3405.jpg',
    '_MG_3407.jpg',
    '_MG_3408.jpg',
    '_MG_3409.jpg',
    '_MG_3422.jpg',
    '_MG_3427.jpg',
    '_MG_3440.jpg',
    '_MG_3446.jpg',
    '_MG_3451.jpg',
    '_MG_3457.jpg',
    '_MG_3462.jpg',
    '_MG_3465.jpg',
    '_MG_3469.jpg',
    '_MG_3470.jpg',
    '_MG_3473.jpg',
    '_MG_3476.jpg',
    '_MG_3478.jpg',
    '_MG_3498.jpg',
    '_MG_3512.jpg',
    '_MG_3514.jpg',
    '_MG_3517.jpg',
    '_MG_3528.jpg',
    '_MG_3534.jpg',
    '_MG_3537.jpg',
    '_MG_3538.jpg',
    '_MG_3548.jpg',
    '_MG_3549.jpg',
    '_MG_3552.jpg',
    '_MG_3556.jpg',
    '_MG_3557.jpg',
    '_MG_3562.jpg',
    '_MG_3564.jpg',
    '_MG_3566.jpg',
    '_MG_3572.jpg',
    '_MG_3573.jpg',
    '_MG_3575.jpg',
    '_MG_3577.jpg',
    '_MG_3583.jpg',
    '_MG_3585.jpg',
    '_MG_3587.jpg',
    '_MG_3591.jpg',
    '_MG_3595.jpg',
    '_MG_3598.jpg',
    '_MG_3599.jpg',
    '_MG_3601.jpg',
    '_MG_3603.jpg',
    '_MG_3604.jpg',
    '_MG_3608.jpg',
    '_MG_3610.jpg',
    '_MG_3611.jpg',
    '_MG_3615.jpg',
    '_MG_3617.jpg',
    '_MG_3621.jpg',
    '_MG_3623.jpg',
    '_MG_3625.jpg',
    '_MG_3627.jpg',
    '_MG_3629.jpg',
    '_MG_3630.jpg',
    '_MG_3633.jpg',
    '_MG_3635.jpg',
    '_MG_3638.jpg',
    '_MG_3641.jpg',
    '_MG_3643.jpg',
    '_MG_3644.jpg',
    '_MG_3646.jpg',
    '_MG_3647.jpg',
  ]
  const downloadImage = (d) => {
    saveAs(`images/fotos/${d}`, d) // Put your image url here.
  }
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight
  const imgStyle =
    screenWidth < screenHeight
      ? { width: screenWidth * 0.95, height: 'auto' }
      : { width: 'auto', height: screenHeight * 0.95 }
  const images = fotosList.map((d) => (
    <div
      style={{
        width: `${screenWidth * 0.95}`,
        height: screenHeight,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={() => downloadImage(d)}
      onKeyDown={() => downloadImage(d)}
      role="presentation"
    >
      <img src={`images/fotos/${d}`} alt={d} style={imgStyle} />
    </div>
  ))
  return (
    <div>
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showIndicators={false}
        showThumbs={false}
      >
        {images}
      </Carousel>
    </div>
  )
}

export default Fotos
