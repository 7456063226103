import React from 'react'
import Home from './home'
import Fotos from './fotos'

import '../css/App.css'

function App() {
  const [isPrice, setIsPrice] = React.useState(false)
  return isPrice ? <Fotos /> : <Home setIsPrice={setIsPrice} />
}

export default App
